@import "./global.scss";

.topBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $color-dark-grey;
  padding: .03rem;
}

// Media queries
@media (min-width: 768px) {
  .topBar {
    flex-wrap: nowrap;
  }
}

@import "./global.scss";

.app {
  background-color: $color-off-white;
  color: $color-black;
  font-family: $font-family;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info, .contact {
  background-color: $color-white;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  width: 80%;
  max-width: 800px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

h4{
  margin: 1rem;
  color: $color-dark-grey;
  margin-bottom: 15px;
  text-align: center;

}

h1, h2 {
  color: $color-dark-grey;
  margin-bottom: 15px;
  text-align: center;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  line-height: 1.6;
  padding: .5px 0;
}

a {
  color: $color-blue;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

// Media queries
@media (min-width: 768px) {
  .info, .contact {
    width: 80%;
  }
}

@media (min-width: 1024px) {
  .info, .contact {
    width: 60%;
  }
}
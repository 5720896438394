@import "./global.scss";

.topBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $color-dark-grey;
  color: $color-white;
  padding: 0.1rem;
  font-size: 0.8rem;
  font-family: $font-family;
  flex-wrap: wrap;
}

.icon {
  display: inline-block;
  margin-right: 0.5rem;
  vertical-align: middle;
}

.leftContainer,
.rightContainer {
  display: flex;
  align-items: center;
  flex: 1 0 100%;
}

.leftContainer {
  p {
    margin-right: 0.2rem;
    margin-left: 2rem;
  }
}

.rightContainer {
  .icon {
    margin-left: 0.5rem;
  }
  margin-top: 0.2rem;
  margin-right: 2rem;
}

// Media queries
@media (min-width: 768px) {
  .topBar {
    flex-wrap: nowrap;
  }

  .leftContainer,
  .rightContainer {
    flex: auto;
  }
}
@import "./global.scss";

.navbar {
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  background-color: $color-white;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

.logo {
  img {
    max-height: 175px;
  }
}

h3 {
  font-size: 1.2rem;
  margin: 0;
  font-weight: 600;
  color: $color-blue;
  font-family: $font-family;
  font-style: italic;
  text-align: center;
  margin-right: 2rem;
}

.text { 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

// Media queries
@media (min-width: 768px) {
  .navbar {
    justify-content: space-around;
  }
}

@media (min-width: 1024px) {
  .navbar {
    justify-content: space-between;
  }
}